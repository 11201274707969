import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";

import AuthPage from "./auth";
import FormChucVu from "./chucvu/FormChucVu";
import ListChucVu from "./chucvu/ListChucVu";
import FormCompany from "./cong-ty/FormCompany";
import ListCompany from "./cong-ty/ListCompany";
import Loading from "./component/Loading";
import DanhSachDuyetGiaiTrinh from "./giai-trinh/DanhSachPheDuyetGiaiTrinh";
import DanhSachGiaiTrinh from "./giai-trinh/DanhSachGiaiTrinh";
import FormDuyetGiaiTrinh from "./giai-trinh/FormDuyetGiaiTrinh";
import FormGiaiTrinh from "./giai-trinh/FormGiaiTrinh";

import HomePage from "./home";
import FormParts from "./phong-ban/FormParts";
import ListParts from "./phong-ban/ListParts";
import ProfilePage from "./profile";
import FormPasswordPage from "./profile/FormPassword";
import FormProfilePage from "./profile/FormProfile";
import { actions } from "./redux/authRedux";
import FormRolesPage from "./phan-quyen/FormRoles";
import ListRolesPage from "./phan-quyen/ListRoles";
import FormSettings from "./settings/FormSettings";
import ListSettingsPage from "./settings/ListSettings";
import TestEmail from "./test-email/TestEmail";
import FormUsersPage from "./nhan-su/FormUsers";
import ListUsersPage from "./nhan-su/ListUsers";
import UploadUsers from "./nhan-su/UploadUsers";
import DanhSachDuyetXinNghi from "./xin-nghi/DanhSachDuyetXinNghi";
import FormDuyetXinNghi from "./xin-nghi/FormDuyetXinNghi";
import FormXinNghi from "./xin-nghi/FormXinNghi";
import DanhSachXinNghi from "./xin-nghi/DanhSachXinNghi";
import FormTimeSettings from "./bang-cham-cong/FormCaiDatChamCong";
import ListTimeSettings from "./bang-cham-cong/CaiDatChamCong";
import ListUsersOnday from "./bang-cham-cong/BangChamCongChiTiet";
import UploadUsersOnday from "./bang-cham-cong/UploadUsersOnday";
import BangChamCongThang from "./bang-cham-cong/BangChamCongThang";
import DanhSachGuiMail from "./test-email/DanhSachGuiMail";
import DanhSachCaLam from "./ca-lam/DanhSachCa";
import FormCa from "./ca-lam/FormCa";
import CaiDatNhanSu from "./nhan-su/CaiDatNhanSu";
import DanhSachNgayLe from "./nghi-le/DanhSachNgayLe";
import FormNgayLe from "./nghi-le/FormNgayLe";
import DanhSachDangKyOL from "./lam-online/DanhSachDangKyOL";
import FormDangKyLamOL from "./lam-online/FormDangKyLamOL";
import XuatBangChamCong from "./bang-cham-cong/XuatBangChamCong";
import ListUserCustimizer from "./nhan-su-tuy-bien/ListUserCustimizer";
import FormUserCustimizer from "./nhan-su-tuy-bien/FormUserCustimizer";
import XuatGiaiTrinhCong from "./giai-trinh/XuatGiaiTrinhCong";
import DangKyLamLe from "./lam-ngay-le/DangKyLamLe";
import FormDangKyLe from "./lam-ngay-le/FormDangKyLe";
import DanhSachDuyetDangKyLe from "./lam-ngay-le/DanhSachDuyetDangKyLe";
import FormDuyetDangKyLe from "./lam-ngay-le/FormDuyetDangKyLe";
import DanhMucTin from "./tin-tuc/DanhMucTin";
import FormDanhMucTin from "./tin-tuc/FormDanhMucTin";
import TinTuc from "./tin-tuc/TinTuc";
import FormTinTuc from "./tin-tuc/FormTinTuc";
import XemTin from "./tin-tuc/XemTin";
import LayMatKhau from "./home/LayMatKhau";
import DoiMatKhau from "./home/DoiMatKhau";
import UploadChamCongBPMAX from "./bang-cham-cong/UploadChamCongBPMAX";
import LoginSSO from "./auth/LoginSSO";
import XuatNhanSu from "./nhan-su/XuatNhanSu";
import QuanLyCa from "./ca-lam-viec/QuanLyCa";
import FormQuanLyCa from "./ca-lam-viec/FormQuanLyCa";
import PhanCaLam from "./ca-lam-viec/PhanCaLam";
import FormPhanCa from "./ca-lam-viec/FormPhanCa";
import DanhSachThucTapSinh from "./nhan-su/DanhSachThucTapSinh";
import FormTTS from "./nhan-su/FormTTS";
import ChotCongThang from "./bang-cham-cong/ChotCongThang";
import BangChotCongThang from "./bang-cham-cong/BangChotCongThang";
import Axios from "axios";
import { URL_LIST_ALL } from "./configs/api";

import ListUsersLogin from "./nhan-su/ListUsersLogin";
import ListUserVote from "./users-vote/ListUserVote";
import ViewEmail from "./test-email/ViewEmail";
import DanhSachLoaiHopDong from "./hop-dong/DanhSachLoaiHopDong";
import FormLoaiHopDong from "./hop-dong/FormLoaiHopDong";
import XuatDangKyCong from "./xin-nghi/XuatDangKyCong";

import Cookies from 'js-cookie';
import DanhSachLog from "./logs/DanhSachLog";
import SuaBangChamCongChiTiet from "./bang-cham-cong/SuaBangChamCongChiTiet";
import SuaBangChotCongThang from "./bang-cham-cong/SuaBangChotCongThang";
import DanhSachHopDong from "./hop-dong/DanhSachHopDong";
import FormHopDong from "./hop-dong/FormHopDong";
import BaoCaoCongViec from "./baocaocongviec/BaoCaoCongViec";
import ThongTinCaNhanForm from "./nhan-su/ThongTinCaNhanForm";
import DanhSachNgayLamBu from "./lam-bu/DanhSachNgayLamBu";
import FormLamBu from "./lam-bu/FormLamBu";
import DanhSachVanThu from "./van-thu-luu-tru/DanhSachVanThu";
import FormVanThu from "./van-thu-luu-tru/FormVanThu";
import Baotri from "./Baotripage/Baotri";
import DanhSachLoaiVanThu from "./van-thu-luu-tru/DanhSachLoaiVanThu";
import FormLoaiVanThu from "./van-thu-luu-tru/FormLoaiVanThu";

function App(props) {
  const { user } = useSelector((state) => state.auth);
  const isAuthorized = user != null;
  const now = parseInt(new Date().getTime() / 1000) + 1 * 60 * 60;
  const dispatch = useDispatch();
  // const crurl = window.location.href
  useEffect(function () {
    let web_url = window.location.hostname
    let pathname = window.location.pathname

    if (web_url === 'hr.bhsgroup.vn') window.location.href = 'https://noibo.bhsgroup.vn' + pathname
    if (isAuthorized && !user.data.roles) dispatch(actions.login(null));
  }, [dispatch, user, isAuthorized])

  const createConfig = (_token) => {
    return {
      headers: {
        'Authorization': 'Bearer ' + _token,
        'Content-Type': 'application/json; charset=UTF-8',
        'search': ''
      }
    }
  }

  useEffect(function () {

    if (isAuthorized && user) {
      Axios.get(URL_LIST_ALL + "users_login/" + user.data.id, user.config)
        .then(async (res) => {
          if (res.data.status === "success") {
            if (res.data.data.expert && res.data.data.expert === 1) {
              dispatch(actions.login(null));
            } else {
              if (res.data.data && res.data.data.data_login) {
                var data_login = res.data.data.data_login
                if (user.data.roles != data_login.data.roles || user.data.master_code != data_login.data.master_code) {
                  data_login.config = createConfig(data_login.access_token);
                  dispatch(actions.login(data_login));
                }
              }
            }
          } else {
            if (res.data.code === 'login') {
              window.location.href = '/auth/login'
            } else {
              console.log(res.data.message);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user, dispatch, isAuthorized])


  return (
    <BrowserRouter basename={""}>
      <Suspense fallback={<Loading color="secondary" />} >
        <Switch>
          {/* <Route path="/">
            <Baotri />
          </Route> */}
          <Route path="/auth">
            <AuthPage></AuthPage>
          </Route>
          <Route path="/login-sso"><LoginSSO /></Route>
          <Route path="/thong-tin-ca-nhan"><ThongTinCaNhanForm /></Route>
          {!isAuthorized ?
            //console.log(encodeURI(crurl))
            <Redirect to="/auth/login" />
            // <Redirect to={crurl !== 'http://localhost:3000/' || crurl !== 'https://hr.bhsgroup.vn/' ? "/auth/login?redirect="+encodeURI(crurl) : "/auth/login"} />
            :
            <>
              {user.expires > now ? <>
                <Route exact path="/"><Redirect to="/dashboard" /></Route>

                <Route path="/dashboard"><HomePage></HomePage></Route>
                <Route path="/list-roles"><ListRolesPage></ListRolesPage></Route>
                <Route path="/edit-roles/:id"><FormRolesPage></FormRolesPage></Route>
                <Route path="/add-roles"><FormRolesPage></FormRolesPage></Route>

                <Route path="/baocaocongviec"><BaoCaoCongViec></BaoCaoCongViec></Route>


                <Route path="/list-users"><ListUsersPage></ListUsersPage></Route>
                <Route path="/edit-users/:id"><FormUsersPage></FormUsersPage></Route>
                <Route path="/add-users"><FormUsersPage></FormUsersPage></Route>
                <Route path="/upload-users"><UploadUsers></UploadUsers></Route>
                <Route path="/export-users"><XuatNhanSu /></Route>
                <Route path="/list-thuc-tap-sinh"><DanhSachThucTapSinh /></Route>
                <Route path="/edit-thuc-tap-sinh/:id"><FormTTS /></Route>
                <Route path="/add-thuc-tap-sinh"><FormTTS /></Route>
                <Route path="/users_login"><ListUsersLogin /></Route>

                <Route path="/users_vote"><ListUserVote /></Route>


                <Route path="/list-users_onday"><ListUsersOnday /></Route>
                <Route path="/edit-users_onday/:id"><SuaBangChamCongChiTiet /></Route>
                <Route path="/list-cham-cong-thang"><BangChamCongThang /></Route>
                <Route path="/upload-users_onday"><UploadUsersOnday /></Route>
                <Route path="/upload-chamcong-bpmax"><UploadChamCongBPMAX /></Route>
                <Route path="/list-users_onday_settings"><ListTimeSettings /></Route>
                <Route path="/add-users_onday_settings"><FormTimeSettings /></Route>
                <Route path="/edit-users_onday_settings/:id"><FormTimeSettings /></Route>

                <Route path="/export-bang-cham-cong-thang"><XuatBangChamCong /></Route>
                <Route path="/chotbangchamcong"><ChotCongThang /></Route>
                <Route path="/bangchotcongthang"><BangChotCongThang /></Route>
                <Route path="/edit-bangchotcongthang/:id"><SuaBangChotCongThang /></Route>

                <Route path="/list-chucvu"><ListChucVu></ListChucVu></Route>
                <Route path="/add-chucvu"><FormChucVu></FormChucVu></Route>
                <Route path="/edit-chucvu/:id"><FormChucVu></FormChucVu></Route>

                <Route path="/list-parts"><ListParts></ListParts></Route>
                <Route path="/add-parts"><FormParts></FormParts></Route>
                <Route path="/edit-parts/:id"><FormParts></FormParts></Route>

                <Route path="/list-company"><ListCompany /></Route>
                <Route path="/add-company"><FormCompany /></Route>
                <Route path="/edit-company/:id"><FormCompany /></Route>

                <Route path="/my-profile"><ProfilePage /></Route>
                {/* <Route path="/update-profile"><FormProfilePage /></Route> */}
                <Route path="/change-password"><FormPasswordPage /></Route>

                <Route path="/list-settings"><ListSettingsPage /></Route>
                <Route path="/add-settings"><FormSettings /></Route>
                <Route path="/edit-settings/:id"><FormSettings /></Route>

                <Route path="/list-users-break"><DanhSachXinNghi /></Route>
                <Route path="/add-users-break"><FormXinNghi /></Route>
                <Route path="/edit-users-break/:id"><FormXinNghi /></Route>
                <Route path="/view-users-break/:id"><FormXinNghi /></Route>
                <Route path="/export-users-break"><XuatDangKyCong /></Route>

                <Route path="/list-approve-break"><DanhSachDuyetXinNghi /></Route>
                <Route path="/view-users-break/:id"><FormDuyetXinNghi /></Route>
                <Route path="/approve-users-break/:id"><FormDuyetXinNghi /></Route>

                <Route path="/list-giaitrinh"><DanhSachGiaiTrinh /></Route>
                <Route path="/add-giaitrinh"><FormGiaiTrinh /></Route>
                <Route path="/edit-giaitrinh/:id"><FormGiaiTrinh /></Route>
                <Route path="/export-giaitrinh"><XuatGiaiTrinhCong /></Route>

                <Route path="/list-duyet-giai-trinh"><DanhSachDuyetGiaiTrinh /></Route>
                <Route path="/approve-duyet-giai-trinh/:id"><FormDuyetGiaiTrinh /></Route>

                <Route path="/test-email"><TestEmail /></Route>
                <Route path="/danh-sach-gui-mail"><DanhSachGuiMail /></Route>
                <Route path="/edit-sendmail/:id"><ViewEmail /></Route>

                <Route path="/logs"><DanhSachLog /></Route>

                <Route path="/list-ca-lam"><DanhSachCaLam /></Route>
                <Route path="/add-ca-lam"><FormCa /></Route>
                <Route path="/edit-ca-lam/:id"><FormCa /></Route>

                <Route path="/quan-ly-ca"><QuanLyCa /></Route>
                <Route path="/add-quan-ly-ca"><FormQuanLyCa /></Route>
                <Route path="/edit-quan-ly-ca/:id"><FormQuanLyCa /></Route>
                <Route path="/phan-ca-lam"><PhanCaLam /></Route>
                <Route path="/add-phan-ca-lam"><FormPhanCa /></Route>
                <Route path="/edit-phan-ca-lam/:id"><FormPhanCa /></Route>

                <Route path="/cai-dat-nhan-su"><CaiDatNhanSu /></Route>

                <Route path="/ngay-le"><DanhSachNgayLe /></Route>
                <Route path="/add-ngay-le"><FormNgayLe /></Route>
                <Route path="/edit-ngay-le/:id"><FormNgayLe /></Route>

                <Route path="/danh-sach-dang-ky-lam-online"><DanhSachDangKyOL /></Route>
                <Route path="/add-dang-ky-lam-online"><FormDangKyLamOL /></Route>
                <Route path="/add-dang-ky-lam-online/:id"><FormDangKyLamOL /></Route>

                <Route path="/users-customizer"><ListUserCustimizer /></Route>
                <Route path="/add-users-customizer"><FormUserCustimizer /></Route>
                <Route path="/edit-users-customizer/:id"><FormUserCustimizer /></Route>

                <Route path="/dang-ky-lam-le"><DangKyLamLe /></Route>
                <Route path="/add-dang-ky-lam-le"><FormDangKyLe /></Route>
                <Route path="/edit-dang-ky-lam-le/:id"><FormDangKyLe /></Route>
                <Route path="/duyet-dang-ky-lam-le"><DanhSachDuyetDangKyLe /></Route>
                <Route path="/approve-dang-ky-lam-le/:id"><FormDuyetDangKyLe /></Route>

                <Route path="/danh-muc-tin"><DanhMucTin /></Route>
                <Route path="/edit-danh-muc-tin/:id"><FormDanhMucTin /></Route>
                <Route path="/add-danh-muc-tin"><FormDanhMucTin /></Route>

                <Route path="/danh-sach-tin"><TinTuc /></Route>
                <Route path="/add-danh-sach-tin"><FormTinTuc /></Route>
                <Route path="/edit-danh-sach-tin/:id"><FormTinTuc /></Route>

                <Route path="/mau-hop-dong"><DanhSachLoaiHopDong /></Route>
                <Route path="/hop-dong"><DanhSachHopDong /></Route>
                <Route path="/add-hop-dong"><FormHopDong /></Route>
                <Route path="/edit-hop-dong/:id"><FormHopDong /></Route>
                <Route path="/add-mau-hop-dong"><FormLoaiHopDong /></Route>
                <Route path="/edit-mau-hop-dong/:id"><FormLoaiHopDong /></Route>

                <Route path="/tin-tuc/:id"><XemTin /></Route>

                <Route path="/lay-mat-khau"><LayMatKhau /></Route>
                <Route path="/doi-mat-khau"><DoiMatKhau /></Route>

                <Route path="/lambu"><DanhSachNgayLamBu /></Route>
                <Route path="/add-lambu"><FormLamBu /></Route>
                <Route path="/edit-lambu/:id"><FormLamBu /></Route>

                <Route path="/van-thu"><DanhSachVanThu /></Route>
                <Route path="/add-van-thu"><FormVanThu /></Route>
                <Route path="/edit-van-thu/:id"><FormVanThu /></Route>

                <Route path="/vanthu_param"><DanhSachLoaiVanThu /></Route>
                <Route path="/add-vanthu_param"><FormLoaiVanThu /></Route>
                <Route path="/edit-vanthu_param/:id"><FormLoaiVanThu /></Route>


              </> : <Loading color="secondary" expire={user.expires <= now} />}
            </>
          }
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
